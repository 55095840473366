/* 巴萨队徽增强主题 - 为网站提供更丰富的巴萨配色元素 */

:root {
  /* 巴萨队徽主色 */
  --fcb-blue: #004e98;
  --fcb-red: #a40142;
  --fcb-gold: #eebd01;
  --fcb-bright-yellow: #fff007;
  --fcb-ball-yellow: #ebba19;
  --fcb-stripe-red: #dc0036;
  --fcb-cross-red: #dd0131;
  --fcb-black: #090505;
  
  /* 渐变色 */
  --fcb-gradient-blue: linear-gradient(45deg, #003b75 0%, #004e98 50%, #3373b3 100%);
  --fcb-gradient-red: linear-gradient(45deg, #7e0132 0%, #a40142 50%, #b73364 100%);
  --fcb-gradient-gold: linear-gradient(45deg, #c99e01 0%, #eebd01 50%, #f1ca3d 100%);
  --fcb-gradient-primary: linear-gradient(45deg, var(--fcb-blue) 0%, var(--fcb-red) 100%);
  --fcb-gradient-secondary: linear-gradient(45deg, var(--fcb-red) 0%, var(--fcb-gold) 100%);
  --fcb-gradient-accent: linear-gradient(45deg, var(--fcb-gold) 0%, var(--fcb-blue) 100%);
  --fcb-gradient-header: linear-gradient(to right, var(--fcb-blue), var(--fcb-red), var(--fcb-gold));
  
  /* 阴影效果 */
  --fcb-shadow-sm: 0 2px 8px rgba(0, 78, 152, 0.15);
  --fcb-shadow-md: 0 4px 12px rgba(0, 78, 152, 0.2);
  --fcb-shadow-lg: 0 8px 24px rgba(0, 78, 152, 0.25);
  --fcb-shadow-gold: 0 4px 12px rgba(238, 189, 1, 0.25);
  --fcb-shadow-red: 0 4px 12px rgba(164, 1, 66, 0.25);
  
  /* 边框效果 */
  --fcb-border-blue: 1px solid rgba(0, 78, 152, 0.3);
  --fcb-border-red: 1px solid rgba(164, 1, 66, 0.3);
  --fcb-border-gold: 1px solid rgba(238, 189, 1, 0.3);
  --fcb-border-gradient: linear-gradient(to right, var(--fcb-blue), var(--fcb-red), var(--fcb-gold));
}

/* 巴萨主题卡片 */
.fcb-themed-card {
  border-radius: 12px;
  overflow: hidden;
  background: linear-gradient(145deg, var(--card-bg) 0%, var(--card-bg) 95%, rgba(0, 78, 152, 0.05) 100%);
  border: 1px solid rgba(238, 189, 1, 0.15);
  box-shadow: var(--fcb-shadow-sm);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.fcb-themed-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--fcb-shadow-md);
  border-color: rgba(238, 189, 1, 0.3);
}

.fcb-themed-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--fcb-gradient-header);
}

/* 巴萨主题按钮 */
.fcb-themed-button {
  border-radius: 8px;
  padding: 10px 24px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  cursor: pointer;
}

.fcb-themed-button.primary {
  background: var(--fcb-gradient-blue);
  color: white;
  box-shadow: var(--fcb-shadow-sm);
}

.fcb-themed-button.secondary {
  background: var(--fcb-gradient-red);
  color: white;
  box-shadow: var(--fcb-shadow-sm);
}

.fcb-themed-button.accent {
  background: var(--fcb-gradient-gold);
  color: black;
  box-shadow: var(--fcb-shadow-sm);
}

.fcb-themed-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--fcb-shadow-md);
}

.fcb-themed-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.6s ease;
}

.fcb-themed-button:hover::before {
  left: 100%;
}

/* 巴萨主题标题 */
.fcb-themed-title {
  font-weight: 700;
  background: var(--fcb-gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 1rem;
}

/* 巴萨主题分隔线 */
.fcb-divider {
  height: 2px;
  width: 100%;
  background: var(--fcb-gradient-header);
  margin: 1.5rem 0;
  border-radius: 2px;
}

/* 巴萨主题背景 */
.fcb-themed-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.03;
  background-repeat: repeat;
  background-size: 200px;
  pointer-events: none;
}

/* 巴萨主题标签 */
.fcb-tag {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.8rem;
  font-weight: 600;
  margin-right: 8px;
  margin-bottom: 8px;
}

.fcb-tag.blue {
  background-color: rgba(0, 78, 152, 0.1);
  color: var(--fcb-blue);
  border: 1px solid rgba(0, 78, 152, 0.2);
}

.fcb-tag.red {
  background-color: rgba(164, 1, 66, 0.1);
  color: var(--fcb-red);
  border: 1px solid rgba(164, 1, 66, 0.2);
}

.fcb-tag.gold {
  background-color: rgba(238, 189, 1, 0.1);
  color: #a08000;
  border: 1px solid rgba(238, 189, 1, 0.2);
}

/* 巴萨主题图标 */
.fcb-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  transition: all 0.3s ease;
}

.fcb-icon.blue {
  background-color: rgba(0, 78, 152, 0.1);
  color: var(--fcb-blue);
}

.fcb-icon.red {
  background-color: rgba(164, 1, 66, 0.1);
  color: var(--fcb-red);
}

.fcb-icon.gold {
  background-color: rgba(238, 189, 1, 0.1);
  color: #a08000;
}

.fcb-icon:hover {
  transform: scale(1.1);
}

/* 巴萨主题输入框 */
.fcb-input {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 10px 16px;
  background-color: var(--input-bg);
  transition: all 0.3s ease;
  width: 100%;
}

.fcb-input:focus {
  outline: none;
  border-color: var(--fcb-gold);
  box-shadow: 0 0 0 2px rgba(238, 189, 1, 0.2);
}

/* 巴萨主题进度条 */
.fcb-progress {
  height: 8px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  margin: 1rem 0;
}

.fcb-progress-bar {
  height: 100%;
  border-radius: 4px;
  background: var(--fcb-gradient-primary);
  transition: width 0.5s ease;
}

/* 巴萨主题通知 */
.fcb-notification {
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  box-shadow: var(--fcb-shadow-sm);
}

.fcb-notification.info {
  background-color: rgba(0, 78, 152, 0.1);
  border-left: 4px solid var(--fcb-blue);
}

.fcb-notification.success {
  background-color: rgba(16, 185, 129, 0.1);
  border-left: 4px solid #10b981;
}

.fcb-notification.warning {
  background-color: rgba(238, 189, 1, 0.1);
  border-left: 4px solid var(--fcb-gold);
}

.fcb-notification.error {
  background-color: rgba(164, 1, 66, 0.1);
  border-left: 4px solid var(--fcb-red);
}

/* 巴萨主题 - 全局样式 */

:root {
  /* 巴萨队徽颜色 */
  --fcb-red-cross: #dd0131;
  --fcb-red-stripes: #dc0036;
  --fcb-yellow-stripes: #fff007;
  --fcb-border-yellow: #eebd01;
  --fcb-ball-yellow: #ebba19;
  --fcb-blue-bottom: #004e98;
  --fcb-red-bottom: #a40142;
  --fcb-black: #090505;
  
  /* 扩展颜色 */
  --fcb-red-dark: #a30031;
  --fcb-red-light: #ff0042;
  --fcb-yellow-dark: #d9a900;
  --fcb-yellow-light: #ffed4a;
  --fcb-blue-dark: #003a73;
  --fcb-blue-light: #0068cc;
  
  /* 中性色 */
  --fcb-white: #ffffff;
  --fcb-gray-light: #f5f5f5;
  --fcb-gray: #cccccc;
  --fcb-gray-dark: #333333;
  
  /* 功能色 */
  --fcb-success: #4caf50;
  --fcb-warning: #ff9800;
  --fcb-error: #f44336;
  --fcb-info: #2196f3;
  
  /* 渐变色 */
  --fcb-gradient-blue-red: linear-gradient(135deg, var(--fcb-blue-bottom) 0%, var(--fcb-red-bottom) 100%);
  --fcb-gradient-yellow-red: linear-gradient(135deg, var(--fcb-ball-yellow) 0%, var(--fcb-red-stripes) 100%);
  --fcb-gradient-blue-yellow: linear-gradient(135deg, var(--fcb-blue-bottom) 0%, var(--fcb-ball-yellow) 100%);
  --fcb-gradient-full: linear-gradient(to bottom, var(--fcb-red-stripes) 0%, var(--fcb-yellow-stripes) 25%, var(--fcb-border-yellow) 50%, var(--fcb-blue-bottom) 75%, var(--fcb-red-bottom) 100%);
  
  /* 阴影 */
  --fcb-shadow-small: 0 2px 8px rgba(9, 5, 5, 0.1);
  --fcb-shadow-medium: 0 4px 16px rgba(9, 5, 5, 0.12);
  --fcb-shadow-large: 0 8px 24px rgba(9, 5, 5, 0.14);
  --fcb-shadow-special: 0 6px 20px rgba(0, 78, 152, 0.25);
  
  /* 图案 */
  --fcb-pattern-stripes: repeating-linear-gradient(90deg, var(--fcb-red-stripes), var(--fcb-red-stripes) 10px, var(--fcb-yellow-stripes) 10px, var(--fcb-yellow-stripes) 20px);
  --fcb-pattern-checkered: repeating-conic-gradient(var(--fcb-red-cross) 0% 25%, var(--fcb-white) 0% 50%);
  --fcb-pattern-diagonal: repeating-linear-gradient(45deg, var(--fcb-blue-bottom), var(--fcb-blue-bottom) 10px, var(--fcb-red-bottom) 10px, var(--fcb-red-bottom) 20px);
  
  /* 边框 */
  --fcb-border-standard: 2px solid var(--fcb-border-yellow);
  --fcb-border-thick: 4px solid var(--fcb-border-yellow);
  --fcb-border-gradient: 2px solid var(--fcb-gradient-full);
  --fcb-border-dashed: 2px dashed var(--fcb-border-yellow);
  
  /* 圆角 */
  --fcb-border-radius-small: 4px;
  --fcb-border-radius-medium: 8px;
  --fcb-border-radius-large: 12px;
  --fcb-border-radius-xl: 16px;
  --fcb-border-radius-round: 50%;
  
  /* 间距 */
  --fcb-spacing-xs: 4px;
  --fcb-spacing-sm: 8px;
  --fcb-spacing-md: 16px;
  --fcb-spacing-lg: 24px;
  --fcb-spacing-xl: 32px;
  
  /* 字体 */
  --fcb-font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --fcb-font-size-xs: 0.75rem;
  --fcb-font-size-sm: 0.875rem;
  --fcb-font-size-md: 1rem;
  --fcb-font-size-lg: 1.25rem;
  --fcb-font-size-xl: 1.5rem;
  --fcb-font-size-xxl: 2rem;
  
  /* 过渡 */
  --fcb-transition-fast: all 0.2s ease;
  --fcb-transition-normal: all 0.3s ease;
  --fcb-transition-slow: all 0.5s ease;
}

/* 亮色主题变量 */
[data-theme="light"] {
  --bg-color: var(--fcb-white);
  --bg-paper: var(--fcb-gray-light);
  --text-primary: var(--fcb-gray-dark);
  --text-secondary: var(--fcb-gray);
  --border-color: var(--fcb-gray);
  --divider-color: var(--fcb-gray-light);
  --header-bg: var(--fcb-white);
}

/* 暗色主题变量 */
[data-theme="dark"] {
  --bg-color: #121212;
  --bg-paper: #1e1e1e;
  --text-primary: var(--fcb-white);
  --text-secondary: var(--fcb-gray-light);
  --border-color: var(--fcb-gray-dark);
  --divider-color: #2c2c2c;
  --header-bg: #1a1a1a;
}

/* 全局样式 */
body {
  font-family: var(--fcb-font-family);
  transition: var(--fcb-transition-normal);
  background-color: var(--bg-color);
  color: var(--text-primary);
}

/* 巴萨主题特殊元素 */
.fcb-card {
  border-radius: var(--fcb-border-radius-large);
  box-shadow: var(--fcb-shadow-small);
  transition: var(--fcb-transition-normal);
  overflow: hidden;
}

.fcb-card:hover {
  transform: translateY(-4px);
  box-shadow: var(--fcb-shadow-special);
}

.fcb-button {
  border-radius: var(--fcb-border-radius-medium);
  transition: var(--fcb-transition-normal);
  font-weight: 500;
  text-transform: none;
}

.fcb-button:hover {
  transform: translateY(-2px);
}

.fcb-button-primary {
  background-color: var(--fcb-blue-bottom);
  color: var(--fcb-white);
}

.fcb-button-primary:hover {
  background-color: var(--fcb-blue-light);
}

.fcb-button-secondary {
  background-color: var(--fcb-red-bottom);
  color: var(--fcb-white);
}

.fcb-button-secondary:hover {
  background-color: var(--fcb-red-light);
}

.fcb-button-accent {
  background-color: var(--fcb-ball-yellow);
  color: var(--fcb-black);
}

.fcb-button-accent:hover {
  background-color: var(--fcb-yellow-light);
}

/* 巴萨主题背景 */
.fcb-themed-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.03;
  background-repeat: repeat;
  background-size: 200px;
  pointer-events: none;
}

/* 巴萨主题标题 */
.fcb-title {
  color: var(--fcb-blue-bottom);
  font-weight: 700;
  margin-bottom: var(--fcb-spacing-md);
  position: relative;
}

.fcb-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 4px;
  background: var(--fcb-gradient-blue-red);
  border-radius: var(--fcb-border-radius-small);
}

/* 巴萨主题分隔线 */
.fcb-divider {
  height: 2px;
  background: var(--fcb-gradient-full);
  margin: var(--fcb-spacing-md) 0;
  border-radius: var(--fcb-border-radius-small);
}

/* 巴萨主题卡片样式 */
.fcb-gradient-card {
  background: var(--fcb-gradient-blue-red);
  color: var(--fcb-white);
  padding: var(--fcb-spacing-lg);
  border-radius: var(--fcb-border-radius-large);
  box-shadow: var(--fcb-shadow-medium);
}

.fcb-accent-card {
  background-color: var(--fcb-ball-yellow);
  color: var(--fcb-black);
  padding: var(--fcb-spacing-lg);
  border-radius: var(--fcb-border-radius-large);
  box-shadow: var(--fcb-shadow-medium);
}

/* 巴萨主题徽章 */
.fcb-badge {
  display: inline-block;
  padding: var(--fcb-spacing-xs) var(--fcb-spacing-sm);
  border-radius: var(--fcb-border-radius-small);
  font-size: var(--fcb-font-size-xs);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.fcb-badge-primary {
  background-color: var(--fcb-blue-bottom);
  color: var(--fcb-white);
}

.fcb-badge-secondary {
  background-color: var(--fcb-red-bottom);
  color: var(--fcb-white);
}

.fcb-badge-accent {
  background-color: var(--fcb-ball-yellow);
  color: var(--fcb-black);
}

/* 巴萨主题输入框 */
.fcb-input {
  border: 1px solid var(--border-color);
  border-radius: var(--fcb-border-radius-medium);
  padding: var(--fcb-spacing-sm) var(--fcb-spacing-md);
  transition: var(--fcb-transition-normal);
}

.fcb-input:focus {
  border-color: var(--fcb-blue-bottom);
  box-shadow: 0 0 0 2px rgba(0, 78, 152, 0.2);
}

/* 巴萨主题选择框 */
.fcb-select {
  border: 1px solid var(--border-color);
  border-radius: var(--fcb-border-radius-medium);
  padding: var(--fcb-spacing-sm) var(--fcb-spacing-md);
  transition: var(--fcb-transition-normal);
}

.fcb-select:focus {
  border-color: var(--fcb-blue-bottom);
  box-shadow: 0 0 0 2px rgba(0, 78, 152, 0.2);
}

/* 巴萨主题表格 */
.fcb-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: var(--fcb-border-radius-medium);
  overflow: hidden;
}

.fcb-table th {
  background-color: var(--fcb-blue-bottom);
  color: var(--fcb-white);
  padding: var(--fcb-spacing-sm) var(--fcb-spacing-md);
  text-align: left;
}

.fcb-table td {
  padding: var(--fcb-spacing-sm) var(--fcb-spacing-md);
  border-bottom: 1px solid var(--border-color);
}

.fcb-table tr:last-child td {
  border-bottom: none;
}

.fcb-table tr:hover td {
  background-color: rgba(0, 78, 152, 0.05);
}

/* 巴萨主题进度条 */
.fcb-progress {
  height: 8px;
  background-color: var(--fcb-gray-light);
  border-radius: var(--fcb-border-radius-small);
  overflow: hidden;
}

.fcb-progress-bar {
  height: 100%;
  background: var(--fcb-gradient-blue-red);
  border-radius: var(--fcb-border-radius-small);
  transition: width 0.3s ease;
}

/* 巴萨主题提示框 */
.fcb-tooltip {
  position: relative;
  display: inline-block;
}

.fcb-tooltip-text {
  visibility: hidden;
  background-color: var(--fcb-blue-dark);
  color: var(--fcb-white);
  text-align: center;
  padding: var(--fcb-spacing-xs) var(--fcb-spacing-sm);
  border-radius: var(--fcb-border-radius-small);
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.fcb-tooltip:hover .fcb-tooltip-text {
  visibility: visible;
  opacity: 1;
}