/* 巴萨队徽缤纷主题 - 为网站提供更加丰富多彩的视觉体验 */

:root {
  /* 扩展巴萨配色 - 更多鲜艳的变体 */
  --fcb-blue-vibrant: #0066cc;
  --fcb-red-vibrant: #cc0052;
  --fcb-gold-vibrant: #ffcc00;
  --fcb-purple: #8a2be2; /* 融合蓝红的紫色 */
  --fcb-teal: #00b3b3;   /* 融合蓝黄的青色 */
  --fcb-orange: #ff8c00;  /* 融合红黄的橙色 */
  
  /* 更多渐变效果 */
  --fcb-gradient-rainbow: linear-gradient(to right, var(--fcb-blue), var(--fcb-purple), var(--fcb-red), var(--fcb-orange), var(--fcb-gold));
  --fcb-gradient-sunset: linear-gradient(to bottom right, var(--fcb-red-vibrant), var(--fcb-orange), var(--fcb-gold-vibrant));
  --fcb-gradient-ocean: linear-gradient(to bottom right, var(--fcb-blue-vibrant), var(--fcb-teal), #80d8ff);
  --fcb-gradient-fire: linear-gradient(to bottom right, #ff0000, #ff6600, var(--fcb-gold-vibrant));
  --fcb-gradient-cosmic: linear-gradient(135deg, #020024, var(--fcb-blue), var(--fcb-purple));
  
  /* 动画时间 */
  --fcb-animation-slow: 3s;
  --fcb-animation-medium: 1.5s;
  --fcb-animation-fast: 0.8s;
  
  /* 特效阴影 */
  --fcb-glow-blue: 0 0 20px rgba(0, 78, 152, 0.7);
  --fcb-glow-red: 0 0 20px rgba(164, 1, 66, 0.7);
  --fcb-glow-gold: 0 0 20px rgba(238, 189, 1, 0.7);
  --fcb-glow-rainbow: 0 0 25px rgba(255, 255, 255, 0.8);
}

/* ===== 缤纷背景效果 ===== */

/* 彩虹渐变背景 */
.fcb-bg-rainbow {
  background: var(--fcb-gradient-rainbow);
  background-size: 400% 400%;
  animation: fcb-gradient-shift var(--fcb-animation-slow) ease infinite;
}

/* 星空背景 */
.fcb-bg-starry {
  background-color: #0a1128;
  background-image: 
    radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
    radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
    radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px);
  background-size: 550px 550px, 350px 350px, 250px 250px;
  background-position: 0 0, 40px 60px, 130px 270px;
}

/* 波浪背景 */
.fcb-bg-wave {
  background: linear-gradient(45deg, var(--fcb-blue-vibrant), var(--fcb-teal));
  position: relative;
  overflow: hidden;
}

.fcb-bg-wave::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 30% 50%, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 25%),
    radial-gradient(circle at 70% 70%, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 25%);
  animation: fcb-wave-animation var(--fcb-animation-slow) ease-in-out infinite alternate;
}

/* 火焰背景 */
.fcb-bg-fire {
  background: var(--fcb-gradient-fire);
  background-size: 200% 200%;
  animation: fcb-fire-animation var(--fcb-animation-medium) ease infinite;
}

/* ===== 缤纷卡片效果 ===== */

/* 全息卡片 */
.fcb-card-holographic {
  background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.3));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255,255,255,0.2);
  box-shadow: 0 8px 32px rgba(0,0,0,0.1);
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}

.fcb-card-holographic::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, 
    rgba(255,255,255,0) 0%, 
    rgba(255,255,255,0.1) 25%, 
    rgba(255,255,255,0.1) 50%, 
    rgba(255,255,255,0) 100%);
  transform: rotate(30deg);
  animation: fcb-holographic-shine var(--fcb-animation-slow) linear infinite;
}

.fcb-card-holographic:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: var(--fcb-glow-rainbow);
}

/* 霓虹卡片 */
.fcb-card-neon {
  background-color: rgba(10, 10, 30, 0.8);
  border-radius: 12px;
  padding: 2px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.fcb-card-neon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--fcb-gradient-rainbow);
  background-size: 400% 400%;
  animation: fcb-gradient-shift var(--fcb-animation-medium) ease infinite;
  z-index: -1;
  filter: blur(5px);
  margin: -5px;
}

.fcb-card-neon-content {
  background-color: rgba(10, 10, 30, 0.95);
  border-radius: 10px;
  padding: 1.5rem;
  height: 100%;
  color: white;
}

/* 3D卡片 */
.fcb-card-3d {
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: all 0.5s ease;
}

.fcb-card-3d-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.fcb-card-3d:hover .fcb-card-3d-inner {
  transform: rotateY(10deg) rotateX(5deg);
  box-shadow: -20px 20px 30px rgba(0,0,0,0.2);
}

/* ===== 缤纷按钮效果 ===== */

/* 霓虹按钮 */
.fcb-button-neon {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  color: white;
  border: 2px solid var(--fcb-blue-vibrant);
  border-radius: 8px;
  padding: 10px 24px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 102, 204, 0.5);
}

.fcb-button-neon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--fcb-blue-vibrant);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease;
}

.fcb-button-neon:hover {
  color: white;
  box-shadow: 0 0 20px rgba(0, 102, 204, 0.8);
}

.fcb-button-neon:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.fcb-button-neon.red {
  border-color: var(--fcb-red-vibrant);
  box-shadow: 0 0 10px rgba(204, 0, 82, 0.5);
}

.fcb-button-neon.red::before {
  background: var(--fcb-red-vibrant);
}

.fcb-button-neon.red:hover {
  box-shadow: 0 0 20px rgba(204, 0, 82, 0.8);
}

.fcb-button-neon.gold {
  border-color: var(--fcb-gold-vibrant);
  color: #000;
  box-shadow: 0 0 10px rgba(255, 204, 0, 0.5);
}

.fcb-button-neon.gold::before {
  background: var(--fcb-gold-vibrant);
}

.fcb-button-neon.gold:hover {
  color: #000;
  box-shadow: 0 0 20px rgba(255, 204, 0, 0.8);
}

/* 脉冲按钮 */
.fcb-button-pulse {
  position: relative;
  background: var(--fcb-gradient-blue);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 24px;
  font-weight: 600;
  overflow: hidden;
  transition: all 0.3s ease;
}

.fcb-button-pulse::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  transform: scale(0);
  animation: fcb-pulse 2s infinite;
}

.fcb-button-pulse:hover {
  transform: translateY(-3px);
  box-shadow: var(--fcb-glow-blue);
}

/* ===== 缤纷文本效果 ===== */

/* 彩虹文本 */
.fcb-text-rainbow {
  background: var(--fcb-gradient-rainbow);
  background-size: 400% 400%;
  animation: fcb-gradient-shift var(--fcb-animation-slow) ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: bold;
}

/* 霓虹文本 */
.fcb-text-neon {
  color: #fff;
  text-shadow: 
    0 0 5px rgba(0, 78, 152, 0.8),
    0 0 10px rgba(0, 78, 152, 0.6),
    0 0 15px rgba(0, 78, 152, 0.4),
    0 0 20px rgba(0, 78, 152, 0.2);
  animation: fcb-neon-pulse var(--fcb-animation-medium) ease-in-out infinite alternate;
}

.fcb-text-neon.red {
  text-shadow: 
    0 0 5px rgba(164, 1, 66, 0.8),
    0 0 10px rgba(164, 1, 66, 0.6),
    0 0 15px rgba(164, 1, 66, 0.4),
    0 0 20px rgba(164, 1, 66, 0.2);
}

.fcb-text-neon.gold {
  text-shadow: 
    0 0 5px rgba(238, 189, 1, 0.8),
    0 0 10px rgba(238, 189, 1, 0.6),
    0 0 15px rgba(238, 189, 1, 0.4),
    0 0 20px rgba(238, 189, 1, 0.2);
}

/* 3D文本 */
.fcb-text-3d {
  color: var(--fcb-blue);
  text-shadow: 
    1px 1px 1px rgba(0,0,0,0.1),
    2px 2px 1px rgba(0,0,0,0.1),
    3px 3px 1px rgba(0,0,0,0.1),
    4px 4px 1px rgba(0,0,0,0.1),
    5px 5px 10px rgba(0,0,0,0.3);
  transition: all 0.3s ease;
}

.fcb-text-3d:hover {
  transform: scale(1.05) translateY(-5px);
}

.fcb-text-3d.red {
  color: var(--fcb-red);
}

/* 渐变按钮 */
.fcb-gradient-button {
  background: var(--fcb-gradient-blue-red);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: var(--fcb-border-radius-medium);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.fcb-gradient-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: var(--fcb-gradient-yellow-red);
  transition: all 0.4s ease;
  z-index: -1;
}

.fcb-gradient-button:hover::before {
  left: 0;
}

.fcb-gradient-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.2);
}

/* 霓虹效果 */
.fcb-neon {
  color: var(--fcb-white);
  text-shadow: 
    0 0 5px var(--fcb-blue-bottom),
    0 0 10px var(--fcb-blue-bottom),
    0 0 20px var(--fcb-blue-bottom),
    0 0 40px var(--fcb-blue-light),
    0 0 80px var(--fcb-blue-light);
}

.fcb-neon-red {
  color: var(--fcb-white);
  text-shadow: 
    0 0 5px var(--fcb-red-bottom),
    0 0 10px var(--fcb-red-bottom),
    0 0 20px var(--fcb-red-bottom),
    0 0 40px var(--fcb-red-light),
    0 0 80px var(--fcb-red-light);
}

.fcb-neon-yellow {
  color: var(--fcb-white);
  text-shadow: 
    0 0 5px var(--fcb-ball-yellow),
    0 0 10px var(--fcb-ball-yellow),
    0 0 20px var(--fcb-ball-yellow),
    0 0 40px var(--fcb-yellow-light),
    0 0 80px var(--fcb-yellow-light);
}

/* 玻璃态效果 */
.fcb-glass {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: var(--fcb-border-radius-large);
  padding: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.fcb-glass-dark {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--fcb-border-radius-large);
  padding: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

/* 彩虹文本 */
.fcb-rainbow-text {
  background: linear-gradient(
    to right,
    var(--fcb-red-bottom),
    var(--fcb-ball-yellow),
    var(--fcb-blue-bottom),
    var(--fcb-red-bottom)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 300% 100%;
  animation: gradientFlow 4s ease infinite;
}

/* 3D按钮 */
.fcb-3d-button {
  background-color: var(--fcb-blue-bottom);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: var(--fcb-border-radius-medium);
  position: relative;
  box-shadow: 0 6px 0 var(--fcb-blue-dark);
  transition: all 0.1s ease;
}

.fcb-3d-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 0 var(--fcb-blue-dark);
}

.fcb-3d-button:active {
  transform: translateY(4px);
  box-shadow: 0 2px 0 var(--fcb-blue-dark);
}

.fcb-3d-button-red {
  background-color: var(--fcb-red-bottom);
  box-shadow: 0 6px 0 var(--fcb-red-dark);
}

.fcb-3d-button-red:hover {
  box-shadow: 0 8px 0 var(--fcb-red-dark);
}

.fcb-3d-button-red:active {
  box-shadow: 0 2px 0 var(--fcb-red-dark);
}

.fcb-3d-button-yellow {
  background-color: var(--fcb-ball-yellow);
  color: var(--fcb-black);
  box-shadow: 0 6px 0 var(--fcb-yellow-dark);
}

.fcb-3d-button-yellow:hover {
  box-shadow: 0 8px 0 var(--fcb-yellow-dark);
}

.fcb-3d-button-yellow:active {
  box-shadow: 0 2px 0 var(--fcb-yellow-dark);
}

/* 闪光边框 */
.fcb-shiny-border {
  position: relative;
  border-radius: var(--fcb-border-radius-large);
  overflow: hidden;
}

.fcb-shiny-border::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(
    45deg,
    var(--fcb-red-bottom),
    var(--fcb-ball-yellow),
    var(--fcb-blue-bottom),
    var(--fcb-red-bottom)
  );
  background-size: 400% 400%;
  animation: gradientFlow 3s ease infinite;
  z-index: -1;
  border-radius: inherit;
}

.fcb-shiny-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
}

/* 条纹背景 */
.fcb-striped-bg {
  background: var(--fcb-pattern-stripes);
  padding: 20px;
  border-radius: var(--fcb-border-radius-large);
}

/* 对角线背景 */
.fcb-diagonal-bg {
  background: var(--fcb-pattern-diagonal);
  padding: 20px;
  border-radius: var(--fcb-border-radius-large);
}

/* 棋盘格背景 */
.fcb-checkered-bg {
  background: var(--fcb-pattern-checkered);
  background-size: 20px 20px;
  padding: 20px;
  border-radius: var(--fcb-border-radius-large);
}

/* 闪烁效果 */
.fcb-sparkle {
  position: relative;
}

.fcb-sparkle::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 
    10px 10px 0 2px var(--fcb-ball-yellow),
    20px 20px 0 -1px var(--fcb-red-bottom),
    30px 10px 0 1px var(--fcb-blue-bottom),
    40px 30px 0 var(--fcb-ball-yellow),
    25px 40px 0 var(--fcb-red-bottom),
    15px 30px 0 -1px var(--fcb-blue-bottom);
  animation: sparkle 4s linear infinite;
}

@keyframes sparkle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

/* 波浪边框 */
.fcb-wavy-border {
  position: relative;
  border-radius: var(--fcb-border-radius-large);
  overflow: hidden;
}

.fcb-wavy-border::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: 
    radial-gradient(
      circle at 100% 100%,
      transparent 15px,
      var(--fcb-blue-bottom) 15px
    ),
    radial-gradient(
      circle at 0 100%,
      transparent 15px,
      var(--fcb-red-bottom) 15px
    ),
    radial-gradient(
      circle at 100% 0,
      transparent 15px,
      var(--fcb-ball-yellow) 15px
    ),
    radial-gradient(
      circle at 0 0,
      transparent 15px,
      var(--fcb-red-stripes) 15px
    );
  background-size: 50% 50%;
  background-position: bottom right, bottom left, top right, top left;
  background-repeat: no-repeat;
  z-index: -1;
  border-radius: inherit;
}

.fcb-wavy-border::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: inherit;
  border-radius: calc(var(--fcb-border-radius-large) - 2px);
  z-index: -1;
}

/* 巴萨队徽形状 */
.fcb-shield-shape {
  position: relative;
  width: 200px;
  height: 240px;
  background: var(--fcb-border-yellow);
  border-radius: 100px 100px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.fcb-shield-shape::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to right, var(--fcb-red-cross) 50%, var(--fcb-pattern-stripes) 50%);
}

.fcb-shield-shape::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: var(--fcb-pattern-diagonal);
}

/* 巴萨主题卡片悬停效果 */
.fcb-hover-card {
  position: relative;
  border-radius: var(--fcb-border-radius-large);
  overflow: hidden;
  transition: all 0.3s ease;
}

.fcb-hover-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 78, 152, 0.7),
    rgba(164, 1, 66, 0.7)
  );
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1;
}

.fcb-hover-card:hover::before {
  opacity: 1;
}

.fcb-hover-card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.fcb-hover-card-content {
  position: relative;
  z-index: 2;
  color: white;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.fcb-hover-card:hover .fcb-hover-card-content {
  opacity: 1;
  transform: translateY(0);
}