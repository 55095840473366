/* 巴萨队徽动画效果 - 为网站提供丰富多彩的动态视觉体验 */

/* ===== 动画关键帧定义 ===== */

/* 渐变位移动画 - 用于彩虹背景效果 */
@keyframes fcb-gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* 波浪动画效果 */
@keyframes fcb-wave-animation {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-10px) scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 0.5;
  }
}

/* 火焰动画效果 */
@keyframes fcb-fire-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* 全息卡片闪光效果 */
@keyframes fcb-holographic-shine {
  0% {
    transform: translateX(-100%) rotate(30deg);
  }
  100% {
    transform: translateX(200%) rotate(30deg);
  }
}

/* 脉冲动画效果 */
@keyframes fcb-pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* 霓虹文本脉冲效果 */
@keyframes fcb-neon-pulse {
  0% {
    text-shadow: 
      0 0 5px rgba(0, 78, 152, 0.8),
      0 0 10px rgba(0, 78, 152, 0.6),
      0 0 15px rgba(0, 78, 152, 0.4),
      0 0 20px rgba(0, 78, 152, 0.2);
  }
  100% {
    text-shadow: 
      0 0 10px rgba(0, 78, 152, 0.9),
      0 0 20px rgba(0, 78, 152, 0.7),
      0 0 30px rgba(0, 78, 152, 0.5),
      0 0 40px rgba(0, 78, 152, 0.3);
  }
}

/* 浮动动画 */
@keyframes fcb-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* 旋转动画 */
@keyframes fcb-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 闪烁动画 */
@keyframes fcb-blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* 弹跳动画 */
@keyframes fcb-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* 摇晃动画 */
@keyframes fcb-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}

/* ===== 动画应用类 ===== */

/* 浮动元素 */
.fcb-animate-float {
  animation: fcb-float 3s ease-in-out infinite;
}

/* 旋转元素 */
.fcb-animate-rotate {
  animation: fcb-rotate 8s linear infinite;
}

/* 慢速旋转 */
.fcb-animate-rotate-slow {
  animation: fcb-rotate 15s linear infinite;
}

/* 闪烁元素 */
.fcb-animate-blink {
  animation: fcb-blink 2s ease-in-out infinite;
}

/* 弹跳元素 */
.fcb-animate-bounce {
  animation: fcb-bounce 2s ease infinite;
}

/* 摇晃元素 - 悬停时触发 */
.fcb-animate-shake:hover {
  animation: fcb-shake 0.5s ease-in-out;
}

/* 组合动画 - 浮动并旋转 */
.fcb-animate-float-rotate {
  animation: fcb-float 3s ease-in-out infinite, fcb-rotate 8s linear infinite;
}

/* 延迟动画类 */
.fcb-animation-delay-1 {
  animation-delay: 0.1s;
}

.fcb-animation-delay-2 {
  animation-delay: 0.2s;
}

.fcb-animation-delay-3 {
  animation-delay: 0.3s;
}

.fcb-animation-delay-4 {
  animation-delay: 0.4s;
}

.fcb-animation-delay-5 {
  animation-delay: 0.5s;
}

/* 巴萨主题 - 动画效果 */

/* 淡入动画 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 上移淡入动画 */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 下移淡入动画 */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 左移淡入动画 */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* 右移淡入动画 */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* 缩放动画 */
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* 脉冲动画 */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 78, 152, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(0, 78, 152, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 78, 152, 0);
  }
}

/* 旋转动画 */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 波浪动画 */
@keyframes wave {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* 闪烁动画 */
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* 渐变背景动画 */
@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* 边框闪光动画 */
@keyframes borderGlow {
  0% {
    box-shadow: 0 0 0 0 rgba(238, 189, 1, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(238, 189, 1, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(238, 189, 1, 0);
  }
}

/* 巴萨队徽颜色交替动画 */
@keyframes fcbColorCycle {
  0% {
    color: var(--fcb-blue-bottom);
  }
  33% {
    color: var(--fcb-red-bottom);
  }
  66% {
    color: var(--fcb-ball-yellow);
  }
  100% {
    color: var(--fcb-blue-bottom);
  }
}

/* 巴萨队徽背景颜色交替动画 */
@keyframes fcbBgColorCycle {
  0% {
    background-color: var(--fcb-blue-bottom);
  }
  33% {
    background-color: var(--fcb-red-bottom);
  }
  66% {
    background-color: var(--fcb-ball-yellow);
  }
  100% {
    background-color: var(--fcb-blue-bottom);
  }
}

/* 巴萨队徽边框颜色交替动画 */
@keyframes fcbBorderColorCycle {
  0% {
    border-color: var(--fcb-blue-bottom);
  }
  33% {
    border-color: var(--fcb-red-bottom);
  }
  66% {
    border-color: var(--fcb-ball-yellow);
  }
  100% {
    border-color: var(--fcb-blue-bottom);
  }
}

/* 应用动画的类 */
.fcb-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.fcb-fade-in-up {
  animation: fadeInUp 0.5s ease-in-out;
}

.fcb-fade-in-down {
  animation: fadeInDown 0.5s ease-in-out;
}

.fcb-fade-in-left {
  animation: fadeInLeft 0.5s ease-in-out;
}

.fcb-fade-in-right {
  animation: fadeInRight 0.5s ease-in-out;
}

.fcb-scale {
  animation: scale 2s ease-in-out infinite;
}

.fcb-pulse {
  animation: pulse 2s infinite;
}

.fcb-spin {
  animation: spin 10s linear infinite;
}

.fcb-wave {
  animation: wave 2s ease-in-out infinite;
}

.fcb-blink {
  animation: blink 1.5s ease-in-out infinite;
}

.fcb-gradient-flow {
  background: linear-gradient(270deg, var(--fcb-blue-bottom), var(--fcb-red-bottom), var(--fcb-ball-yellow));
  background-size: 600% 600%;
  animation: gradientFlow 8s ease infinite;
}

.fcb-border-glow {
  animation: borderGlow 2s infinite;
}

.fcb-color-cycle {
  animation: fcbColorCycle 6s infinite;
}

.fcb-bg-color-cycle {
  animation: fcbBgColorCycle 6s infinite;
}

.fcb-border-color-cycle {
  animation: fcbBorderColorCycle 6s infinite;
}

/* 动画延迟类 */
.fcb-delay-1 {
  animation-delay: 0.1s;
}

.fcb-delay-2 {
  animation-delay: 0.2s;
}

.fcb-delay-3 {
  animation-delay: 0.3s;
}

.fcb-delay-4 {
  animation-delay: 0.4s;
}

.fcb-delay-5 {
  animation-delay: 0.5s;
}

/* 动画持续时间类 */
.fcb-duration-1 {
  animation-duration: 1s;
}

.fcb-duration-2 {
  animation-duration: 2s;
}

.fcb-duration-3 {
  animation-duration: 3s;
}

.fcb-duration-4 {
  animation-duration: 4s;
}

.fcb-duration-5 {
  animation-duration: 5s;
}

/* 特殊动画效果 */
.fcb-logo-pulse {
  animation: pulse 2s infinite;
  transform-origin: center;
}

.fcb-button-hover {
  transition: all 0.3s ease;
}

.fcb-button-hover:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1);
}

.fcb-card-hover {
  transition: all 0.3s ease;
}

.fcb-card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

/* 页面过渡动画 */
.fcb-page-transition-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fcb-page-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.3s;
}

.fcb-page-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.fcb-page-transition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.3s;
}