:root {
  --transition-speed: 0.3s;
}

/* 浅色主题变量 - 巴萨队徽配色 */
[data-theme='light'], [data-theme=light] {
  --bg-color: #f5f6f7;
  --text-primary: #333333;
  --text-secondary: #666666;
  --primary-color: #004e98; /* 巴萨蓝色 */
  --primary-color-dark: #003b75;
  --secondary-color: #a40142; /* 巴萨红色 */
  --secondary-color-dark: #7e0132;
  --accent-color: #eebd01; /* 巴萨金黄色 */
  --accent-color-dark: #c99e01;
  --danger-color: #dc0036; /* 巴萨条纹红 */
  --danger-color-dark: #b50029;
  --card-bg: #ffffff;
  --border-color: #e0e0e0;
  --input-bg: #ffffff;
  --hover-bg: rgba(0, 0, 0, 0.05);
  --header-bg: #ffffff;
}

/* 深色主题变量 - 巴萨队徽配色 */
[data-theme='dark'], [data-theme=dark] {
  --bg-color: #121212;
  --text-primary: #ffffff;
  --text-secondary: #b0b0b0;
  --primary-color: #004e98; /* 巴萨蓝色 */
  --primary-color-dark: #003b75;
  --secondary-color: #a40142; /* 巴萨红色 */
  --secondary-color-dark: #7e0132;
  --accent-color: #eebd01; /* 巴萨金黄色 */
  --accent-color-dark: #c99e01;
  --danger-color: #dc0036; /* 巴萨条纹红 */
  --danger-color-dark: #b50029;
  --card-bg: #1e1e1e;
  --border-color: #333333;
  --input-bg: #2c2c2c;
  --hover-bg: rgba(255, 255, 255, 0.05);
  --header-bg: #1e1e1e;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  color: var(--text-primary);
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, select, button {
  font-family: inherit;
}

/* 添加平滑过渡效果 */
.app, .app-header, .wallet-dashboard, .transaction-panel,
.settings-panel, .wallet-balance, .wallet-actions,
input, select, button {
  transition: all var(--transition-speed) ease;
}

/* 确保背景色和文本颜色正确应用 */
.app {
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--text-primary);
}

/* 修复卡片背景和边框 */
.card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  transition: all var(--transition-speed) ease;
}

/* 输入框样式 */
input, select {
  background-color: var(--input-bg);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 8px 12px;
}

input:focus, select:focus {
  outline: none;
  border-color: var(--primary-color);
}

/* 按钮样式 */
button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color var(--transition-speed);
}

button:hover {
  background-color: var(--primary-color-dark);
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* 链接样式 */
a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color var(--transition-speed);
}

a:hover {
  color: var(--primary-color-dark);
}
