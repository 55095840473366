/* 巴萨队徽主题变量 */
:root {
  /* 浅色主题 - 巴萨配色 */
  --light-bg: #f7fafc;
  --light-card-bg: #ffffff;
  --light-text: #2d3748;
  --light-text-secondary: #4a5568;
  --light-border: #e2e8f0;
  --light-hover: #edf2f7;
  --light-button: #004e98; /* 巴萨蓝色 */
  --light-button-hover: #003b75;
  --light-accent: #eebd01; /* 巴萨金黄色 */
  --light-secondary: #a40142; /* 巴萨红色 */
}

[data-theme='light'] {
  --bg-color: var(--light-bg);
  --card-bg: var(--light-card-bg);
  --text-color: var(--light-text);
  --text-secondary: var(--light-text-secondary);
  --border-color: var(--light-border);
  --hover-color: var(--light-hover);
  --button-color: var(--light-button);
  --button-hover: var(--light-button-hover);
  --accent-color: var(--light-accent);
  --secondary-color: var(--light-secondary);
}

[data-theme='dark'] {
  --bg-color: #1a1d24;
  --card-bg: #2c313c;
  --text-color: #ffffff;
  --text-secondary: #a0aec0;
  --border-color: #3d4451;
  --hover-color: #363b47;
  --button-color: #004e98; /* 巴萨蓝色 */
  --button-hover: #003b75;
  --accent-color: #eebd01; /* 巴萨金黄色 */
  --secondary-color: #a40142; /* 巴萨红色 */
}

/* 应用全局样式 */
.App {
  text-align: center;
  background-color: var(--bg-color);
  min-height: 100vh;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 1vmin);
  color: var(--text-color);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 应用控制栏 */
.app-controls {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* 语言切换 */
.language-switch select {
  background-color: var(--card-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
}

/* 主题切换按钮 */
.theme-toggle {
  background-color: var(--card-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.theme-toggle:hover {
  background-color: var(--hover-color);
}

/* 交易筛选样式 */
.transactions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.transaction-filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.transaction-filters select {
  background-color: var(--card-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
}

.date-range {
  display: flex;
  gap: 0.5rem;
}

.date-range input {
  background-color: var(--card-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  border-radius: 8px;
}

/* 钱包容器样式 */
.wallet-container {
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: var(--card-bg);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);
}

/* 钱包头部样式 */
.wallet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #3d4451;
}

.network-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #a0aec0;
}

.network-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.network-indicator.mainnet {
  background-color: #48bb78;
}

.network-indicator.testnet {
  background-color: #ed8936;
}

/* 按钮样式 */
.connect-button {
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.connect-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.3);
}

.disconnect-button {
  background-color: #4a5568;
  color: #cbd5e0;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 8px;
}

.disconnect-button:hover {
  background-color: #2d3748;
}

/* 钱包信息样式 */
.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.info-item {
  background-color: var(--card-bg);
  padding: 1rem;
  border-radius: 8px;
}

.info-item label {
  display: block;
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.info-item p {
  margin: 0;
  word-break: break-all;
  font-size: 1rem;
  font-family: monospace;
  background-color: var(--hover-color);
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
}

/* 转账表单样式 */
.transfer-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
}

.input-group label {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.amount-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.amount-input input {
  padding-right: 60px;
}

.currency-symbol {
  position: absolute;
  right: 16px;
  color: var(--text-secondary);
  font-weight: 500;
  pointer-events: none;
}

/* 输入框样式优化 */
.input-group input {
  width: 100%;
  padding: 12px 16px;
  background-color: var(--card-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.input-group input:focus {
  outline: none;
  border-color: var(--button-color);
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
}

.input-group input::placeholder {
  color: var(--text-secondary);
}

/* 地址输入框特殊样式 */
.input-group input[type="text"] {
  font-family: monospace;
  letter-spacing: 0.5px;
}

/* 金额输入框特殊样式 */
.input-group input[type="number"] {
  font-family: 'Arial', sans-serif;
  font-weight: 500;
}

/* 移除数字输入框的箭头 */
.input-group input[type="number"]::-webkit-inner-spin-button,
.input-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.send-button {
  background: linear-gradient(135deg, #48bb78 0%, #38a169 100%);
  padding: 1rem;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 1rem;
}

.send-button:disabled {
  background: #4a5568;
  cursor: not-allowed;
}

/* 交易列表样式 */
.transaction-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.transaction-item {
  display: flex;
  gap: 1rem;
  background-color: var(--card-bg);
  padding: 1.5rem;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid var(--border-color);
}

.transaction-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tx-icon {
  width: 40px;
  height: 40px;
  background-color: #4a5568;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tx-info {
  flex: 1;
}

.tx-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.tx-type {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.tx-amount {
  font-weight: 600;
  color: #48bb78;
}

.tx-details {
  color: #a0aec0;
  font-size: 0.9rem;
}

.tx-details p {
  margin: 0.2rem 0;
}

.tx-link {
  display: inline-block;
  margin-top: 0.5rem;
  color: #6366f1;
  text-decoration: none;
  font-size: 0.9rem;
}

.tx-link:hover {
  text-decoration: underline;
}

.no-transactions {
  text-align: center;
  color: #a0aec0;
  padding: 2rem;
  background-color: #363b47;
  border-radius: 8px;
}

/* 错误消息样式 */
.error-message {
  background-color: #fc8181;
  color: #fff;
  padding: 0.75rem;
  border-radius: 8px;
  margin: 1rem 0;
  text-align: center;
}

/* 加载动画 */
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 钱包选择模态框样式更新 */
.wallet-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.wallet-modal-content {
  background-color: #2c313c;
  padding: 2rem;
  border-radius: 16px;
  min-width: 320px;
  max-width: 480px;
  position: relative;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.wallet-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;
}

.wallet-option {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #363b47;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.wallet-option:hover {
  background-color: #3d4451;
  border-color: #6366f1;
  transform: translateY(-2px);
}

.wallet-option.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.wallet-option img {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  object-fit: contain;
  background-color: white;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.wallet-option span {
  font-size: 1.1rem;
  color: white;
  flex: 1;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  border: none;
  color: #a0aec0;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.5rem;
  line-height: 1;
  transition: all 0.3s ease;
}

.close-modal:hover {
  color: white;
  transform: rotate(90deg);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .transaction-filters {
    flex-direction: column;
    align-items: stretch;
  }
  
  .date-range {
    flex-direction: column;
  }
  
  .app-controls {
    flex-direction: column;
    align-items: stretch;
  }
}

/* 主内容区样式 */
.main-content {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  background: var(--card-bg);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.section h2 {
  margin-bottom: 20px;
  color: var(--text-primary);
  font-size: 1.5rem;
}

/* 钱包信息样式 */
.wallet-container {
  width: 100%;
}

.wallet-header {
  background: var(--card-bg);
  padding: 15px 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.network-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.network-indicator {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
}

.network-indicator.mainnet {
  background: #28a745;
  color: white;
}

.network-indicator.testnet {
  background: #ffc107;
  color: black;
}

.account-info {
  font-family: monospace;
  background: var(--bg-secondary);
  padding: 6px 12px;
  border-radius: 6px;
}

.balance-info {
  font-weight: bold;
  color: var(--text-primary);
}

/* 质押区域样式 */
.stake-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* NFT区域样式 */
.nft-marketplace {
  display: grid;
  gap: 20px;
}

.nft-actions {
  display: flex;
  gap: 10px;
}

.nft-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

/* 交易历史样式 */
.transaction-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.transaction-filters select {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  background: var(--bg-secondary);
  color: var(--text-primary);
}

.transaction-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.transaction-item {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  gap: 15px;
  padding: 12px;
  background: var(--bg-secondary);
  border-radius: 8px;
}

.tx-type {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.tx-hash a {
  color: var(--primary-color);
  text-decoration: none;
}

.tx-time {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

/* 转账表单样式 */
.transfer-form {
  display: grid;
  gap: 15px;
  max-width: 500px;
}

.transfer-form input {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background: var(--bg-secondary);
  color: var(--text-primary);
}

.transfer-form button {
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: var(--primary-color);
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.2s;
}

.transfer-form button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* 深色模式变量 */
[data-theme='dark'] {
  --bg-primary: #1a1a1a;
  --bg-secondary: #2d2d2d;
  --text-primary: #ffffff;
  --text-secondary: #a0a0a0;
  --border-color: #404040;
  --card-bg: #2d2d2d;
  --primary-color: #3498db;
}

/* 浅色模式变量 */
[data-theme='light'] {
  --bg-primary: #ffffff;
  --bg-secondary: #f5f5f5;
  --text-primary: #333333;
  --text-secondary: #666666;
  --border-color: #e0e0e0;
  --card-bg: #ffffff;
  --primary-color: #3498db;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .transaction-item {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  
  .network-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .stake-cards {
    grid-template-columns: 1fr;
  }
}
