/* 巴萨队徽主题动画效果 */

/* 基础过渡效果 */
.fcb-transition {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* 渐入效果 */
.fcb-fade-in {
  animation: fcbFadeIn 0.5s ease forwards;
}

@keyframes fcbFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* 上移渐入效果 */
.fcb-fade-up {
  animation: fcbFadeUp 0.6s ease forwards;
}

@keyframes fcbFadeUp {
  from { 
    opacity: 0; 
    transform: translateY(20px);
  }
  to { 
    opacity: 1; 
    transform: translateY(0);
  }
}

/* 巴萨蓝色脉冲效果 */
.fcb-pulse-blue {
  animation: fcbPulseBlue 2s infinite;
}

@keyframes fcbPulseBlue {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 78, 152, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 78, 152, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 78, 152, 0);
  }
}

/* 巴萨金色闪烁效果 */
.fcb-shimmer-gold {
  position: relative;
  overflow: hidden;
}

.fcb-shimmer-gold::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(to right, rgba(238, 189, 1, 0) 0%, rgba(238, 189, 1, 0.3) 50%, rgba(238, 189, 1, 0) 100%);
  transform: rotate(30deg);
  animation: fcbShimmerGold 3s infinite;
}

@keyframes fcbShimmerGold {
  from { transform: translateX(-100%) rotate(30deg); }
  to { transform: translateX(100%) rotate(30deg); }
}

/* 巴萨红色边框动画 */
.fcb-border-pulse {
  position: relative;
}

.fcb-border-pulse::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #a40142;
  border-radius: inherit;
  animation: fcbBorderPulse 2s infinite;
}

@keyframes fcbBorderPulse {
  0% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(1.05); }
  100% { opacity: 1; transform: scale(1); }
}

/* 按钮悬停效果 */
.fcb-button {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 1;
}

.fcb-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.6s ease;
  z-index: -1;
}

.fcb-button:hover::before {
  left: 100%;
}

/* 卡片悬停效果 */
.fcb-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fcb-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* 页面切换动画 */
.fcb-page-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fcb-page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

.fcb-page-exit {
  opacity: 1;
}

.fcb-page-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}

/* 加载动画 - 巴萨队徽颜色 */
.fcb-loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.fcb-loading div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation: fcbLoading 1.2s linear infinite;
}

.fcb-loading div:nth-child(1) {
  top: 6px;
  left: 6px;
  background: #004e98; /* 巴萨蓝 */
  animation-delay: 0s;
}

.fcb-loading div:nth-child(2) {
  top: 6px;
  left: 26px;
  background: #a40142; /* 巴萨红 */
  animation-delay: -0.4s;
}

.fcb-loading div:nth-child(3) {
  top: 6px;
  left: 45px;
  background: #eebd01; /* 巴萨金 */
  animation-delay: -0.8s;
}

@keyframes fcbLoading {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.5);
  }
}