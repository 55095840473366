/* 巴萨队徽增强主题 - 为网站提供更全面的巴萨配色元素和组件 */

/* 扩展巴萨配色变量 */
:root {
  /* 基础巴萨配色 */
  --fcb-blue: #004e98;
  --fcb-red: #a40142;
  --fcb-gold: #eebd01;
  --fcb-bright-yellow: #fff007;
  --fcb-ball-yellow: #ebba19;
  --fcb-stripe-red: #dc0036;
  --fcb-cross-red: #dd0131;
  --fcb-black: #090505;
  
  /* 扩展色调 - 蓝色系列 */
  --fcb-blue-100: #e6f0f9;
  --fcb-blue-200: #b3d1ed;
  --fcb-blue-300: #80b2e0;
  --fcb-blue-400: #4d93d4;
  --fcb-blue-500: #1a74c7;
  --fcb-blue-600: #005fb9;
  --fcb-blue-700: #004e98; /* 主蓝色 */
  --fcb-blue-800: #003b75;
  --fcb-blue-900: #002952;
  
  /* 扩展色调 - 红色系列 */
  --fcb-red-100: #f9e6ee;
  --fcb-red-200: #edb3ce;
  --fcb-red-300: #e080ae;
  --fcb-red-400: #d44d8e;
  --fcb-red-500: #c71a6e;
  --fcb-red-600: #b90058;
  --fcb-red-700: #a40142; /* 主红色 */
  --fcb-red-800: #7e0132;
  --fcb-red-900: #570023;
  
  /* 扩展色调 - 金色系列 */
  --fcb-gold-100: #fef9e6;
  --fcb-gold-200: #fbedb3;
  --fcb-gold-300: #f9e080;
  --fcb-gold-400: #f6d44d;
  --fcb-gold-500: #f4c71a;
  --fcb-gold-600: #f1b900;
  --fcb-gold-700: #eebd01; /* 主金色 */
  --fcb-gold-800: #c99e01;
  --fcb-gold-900: #a38000;
  
  /* 渐变色 - 更多选项 */
  --fcb-gradient-blue: linear-gradient(45deg, #003b75 0%, #004e98 50%, #3373b3 100%);
  --fcb-gradient-red: linear-gradient(45deg, #7e0132 0%, #a40142 50%, #b73364 100%);
  --fcb-gradient-gold: linear-gradient(45deg, #c99e01 0%, #eebd01 50%, #f1ca3d 100%);
  --fcb-gradient-primary: linear-gradient(45deg, var(--fcb-blue) 0%, var(--fcb-red) 100%);
  --fcb-gradient-secondary: linear-gradient(45deg, var(--fcb-red) 0%, var(--fcb-gold) 100%);
  --fcb-gradient-accent: linear-gradient(45deg, var(--fcb-gold) 0%, var(--fcb-blue) 100%);
  --fcb-gradient-header: linear-gradient(to right, var(--fcb-blue), var(--fcb-red), var(--fcb-gold));
  --fcb-gradient-diagonal: linear-gradient(135deg, var(--fcb-blue) 0%, var(--fcb-red) 50%, var(--fcb-gold) 100%);
  --fcb-gradient-radial: radial-gradient(circle, var(--fcb-gold) 0%, var(--fcb-red) 50%, var(--fcb-blue) 100%);
  
  /* 阴影效果 */
  --fcb-shadow-sm: 0 2px 8px rgba(0, 78, 152, 0.15);
  --fcb-shadow-md: 0 4px 12px rgba(0, 78, 152, 0.2);
  --fcb-shadow-lg: 0 8px 24px rgba(0, 78, 152, 0.25);
  --fcb-shadow-gold: 0 4px 12px rgba(238, 189, 1, 0.25);
  --fcb-shadow-red: 0 4px 12px rgba(164, 1, 66, 0.25);
  --fcb-shadow-blue-glow: 0 0 15px rgba(0, 78, 152, 0.5);
  --fcb-shadow-gold-glow: 0 0 15px rgba(238, 189, 1, 0.5);
  --fcb-shadow-red-glow: 0 0 15px rgba(164, 1, 66, 0.5);
  
  /* 边框效果 */
  --fcb-border-blue: 1px solid rgba(0, 78, 152, 0.3);
  --fcb-border-red: 1px solid rgba(164, 1, 66, 0.3);
  --fcb-border-gold: 1px solid rgba(238, 189, 1, 0.3);
  --fcb-border-gradient: linear-gradient(to right, var(--fcb-blue), var(--fcb-red), var(--fcb-gold));
  --fcb-border-thick-blue: 3px solid var(--fcb-blue);
  --fcb-border-thick-red: 3px solid var(--fcb-red);
  --fcb-border-thick-gold: 3px solid var(--fcb-gold);
}

/* ===== 增强的组件样式 ===== */

/* 巴萨主题页面容器 */
.fcb-page-container {
  background-image: linear-gradient(to bottom right, 
    rgba(0, 78, 152, 0.03), 
    transparent, 
    rgba(238, 189, 1, 0.02), 
    transparent, 
    rgba(164, 1, 66, 0.02)
  );
  min-height: 100vh;
  padding: 2rem;
  position: relative;
}

.fcb-page-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--fcb-gradient-header);
}

/* 巴萨主题卡片 - 多种风格 */
.fcb-card {
  border-radius: 12px;
  overflow: hidden;
  background: linear-gradient(145deg, var(--card-bg) 0%, var(--card-bg) 95%, rgba(0, 78, 152, 0.05) 100%);
  border: 1px solid rgba(238, 189, 1, 0.15);
  box-shadow: var(--fcb-shadow-sm);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.fcb-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--fcb-shadow-md);
  border-color: rgba(238, 189, 1, 0.3);
}

.fcb-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--fcb-gradient-header);
}

/* 蓝色主题卡片 */
.fcb-card-blue {
  border: var(--fcb-border-blue);
}

.fcb-card-blue::after {
  background: var(--fcb-blue);
}

/* 红色主题卡片 */
.fcb-card-red {
  border: var(--fcb-border-red);
}

.fcb-card-red::after {
  background: var(--fcb-red);
}

/* 金色主题卡片 */
.fcb-card-gold {
  border: var(--fcb-border-gold);
}

.fcb-card-gold::after {
  background: var(--fcb-gold);
}

/* 高级卡片 - 渐变边框 */
.fcb-card-premium {
  position: relative;
  border-radius: 12px;
  background: var(--card-bg);
  z-index: 1;
  padding: 1px;
}

.fcb-card-premium::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  padding: 2px;
  background: var(--fcb-gradient-header);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.fcb-card-premium:hover {
  transform: translateY(-5px);
  box-shadow: var(--fcb-shadow-md);
}

.fcb-card-premium-content {
  background: var(--card-bg);
  border-radius: 10px;
  padding: 1.5rem;
  height: 100%;
}

/* 巴萨主题按钮 - 多种风格 */
.fcb-button {
  border-radius: 8px;
  padding: 10px 24px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.fcb-button.primary {
  background: var(--fcb-gradient-blue);
  color: white;
  box-shadow: var(--fcb-shadow-sm);
}

.fcb-button.secondary {
  background: var(--fcb-gradient-red);
  color: white;
  box-shadow: var(--fcb-shadow-sm);
}

.fcb-button.accent {
  background: var(--fcb-gradient-gold);
  color: black;
  box-shadow: var(--fcb-shadow-sm);
}

.fcb-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--fcb-shadow-md);
}

.fcb-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.6s ease;
}

.fcb-button:hover::before {
  left: 100%;
}

/* 轮廓按钮 */
.fcb-button-outline {
  background: transparent;
  border: 2px solid;
  padding: 9px 23px;
}

.fcb-button-outline.primary {
  border-color: var(--fcb-blue);
  color: var(--fcb-blue);
}

.fcb-button-outline.secondary {
  border-color: var(--fcb-red);
  color: var(--fcb-red);
}

.fcb-button-outline.accent {
  border-color: var(--fcb-gold);
  color: var(--fcb-gold-900);
}

.fcb-button-outline:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* 巴萨主题标题 */
.fcb-title {
  font-weight: 700;
  background: var(--fcb-gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.fcb-title::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 40px;
  height: 3px;
  background: var(--fcb-gradient-header);
  border-radius: 3px;
}

/* 巴萨主题分隔线 */
.fcb-divider {
  height: 2px;
  width: 100%;
  background: var(--fcb-gradient-header);
  margin: 1.5rem 0;
  border-radius: 2px;
}

/* 巴萨主题标签 */
.fcb-tag {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.8rem;
  font-weight: 600;
  margin-right: 8px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.fcb-tag.blue {
  background-color: rgba(0, 78, 152, 0.1);
  color: var(--fcb-blue);
  border: 1px solid rgba(0, 78, 152, 0.2);
}

.fcb-tag.red {
  background-color: rgba(164, 1, 66, 0.1);
  color: var(--fcb-red);
  border: 1px solid rgba(164, 1, 66, 0.2);
}

.fcb-tag.gold {
  background-color: rgba(238, 189, 1, 0.1);
  color: #a08000;
  border: 1px solid rgba(238, 189, 1, 0.2);
}

/* 巴萨主题图标 */
.fcb-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  transition: all 0.3s ease;
}

.fcb-icon.blue {
  background-color: rgba(0, 78, 152, 0.1);
  color: var(--fcb-blue);
}

.fcb-icon.red {
  background-color: rgba(164, 1, 66, 0.1);
  color: var(--fcb-red);
}

.fcb-icon.gold {
  background-color: rgba(238, 189, 1, 0.1);
  color: #a08000;
}

.fcb-icon:hover {
  transform: scale(1.1);
}

/* 巴萨主题输入框 */
.fcb-input {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 10px 16px;
  background-color: var(--input-bg);
  transition: all 0.3s ease;
  width: 100%;
}

.fcb-input:focus {
  outline: none;
  border-color: var(--fcb-gold);
  box-shadow: 0 0 0 2px rgba(238, 189, 1, 0.2);
}

/* 巴萨主题进度条 */
.fcb-progress {
  height: 8px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  margin: 1rem 0;
}

.fcb-progress-bar {
  height: 100%;
  border-radius: 4px;
  background: var(--fcb-gradient-primary);
  transition: width 0.5s ease;
}

/* 巴萨主题通知 */
.fcb-notification {
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  box-shadow: var(--fcb-shadow-sm);
}

.fcb-notification.info {
  background-color: rgba(0, 78, 152, 0.1);
  border-left: 4px solid var(--fcb-blue);
}

.fcb-notification.success {
  background-color: rgba(16, 185, 129, 0.1);
  border-left: 4px solid #10b981;
}

.fcb-notification.warning {
  background-color: rgba(238, 189, 1, 0.1);
  border-left: 4px solid var(--fcb-gold);
}

.fcb-notification.error {
  background-color: rgba(164, 1, 66, 0.1);
  border-left: 4px solid var(--fcb-red);
}

/* 巴萨主题导航 */
.fcb-nav {
  background: var(--card-bg);
  border-bottom: 1px solid var(--border-color);
  box-shadow: var(--fcb-shadow-sm);
  position: sticky;
  top: 0;
  z-index: 100;
}

.fcb-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.fcb-nav-logo {
  font-weight: 700;
  font-size: 1.5rem;
  background: var(--fcb-gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.fcb-nav-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.fcb-nav-link {
  color: var(--text-primary);
  text-decoration: none;
  font-weight: 500;
  position: relative;
  padding: 0.5rem 0;
}

.fcb-nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--fcb-gradient-header);
  transition: width 0.3s ease;
}

.fcb-nav-link:hover::after,
.fcb-nav-link.active::after {
  width: 100%;
}

/* 巴萨主题表格 */
.fcb-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 1rem 0;
}

.fcb-table th,
.fcb-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.fcb-table th {
  font-weight: 600;
  background-color: rgba(0, 78, 152, 0.05);
  color: var(--fcb-blue-800);
}

.fcb-table tr:hover td {
  background-color: rgba(238, 189, 1, 0.05);
}

/* 巴萨主题表单 */
.fcb-form-group {
  margin-bottom: 1.5rem;
}

.fcb-form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-primary);
}

.fcb-form-hint {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.8rem;
  color: var(--text-secondary);
}

/* 巴萨主题开关 */
.fcb-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.fcb-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.fcb-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.fcb-switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .fcb-switch-slider {
  background: var(--fcb-gradient-blue);
}

input:checked + .fcb-switch-slider:before {
  transform: translateX(26px);
}

/* 巴萨主题 - 增强版效果 */

/* 高级卡片效果 */
.fcb-premium-card {
  position: relative;
  background: var(--fcb-white);
  border-radius: var(--fcb-border-radius-large);
  padding: 25px;
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.1),
    0 1px 8px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.fcb-premium-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--fcb-gradient-blue-red);
}

.fcb-premium-card:hover {
  transform: translateY(-10px) rotateX(5deg);
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.14),
    0 10px 20px rgba(0, 0, 0, 0.1);
}

/* 高级按钮效果 */
.fcb-premium-button {
  position: relative;
  background: var(--fcb-blue-bottom);
  color: white;
  border: none;
  padding: 12px 24px;
  font-weight: 600;
  border-radius: var(--fcb-border-radius-medium);
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 1;
}

.fcb-premium-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: translateX(-100%);
  transition: all 0.6s ease;
  z-index: -1;
}

.fcb-premium-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.2);
}

.fcb-premium-button:hover::before {
  transform: translateX(100%);
}

/* 高级标题效果 */
.fcb-premium-title {
  position: relative;
  color: var(--fcb-blue-bottom);
  font-weight: 700;
  margin-bottom: 25px;
  padding-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.fcb-premium-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 4px;
  background: var(--fcb-gradient-blue-red);
  border-radius: var(--fcb-border-radius-small);
  transition: all 0.3s ease;
}

.fcb-premium-title:hover::after {
  width: 100px;
}

/* 高级图标效果 */
.fcb-premium-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--fcb-gradient-blue-yellow);
  color: white;
  box-shadow: 0 5px 15px rgba(0, 78, 152, 0.3);
  transition: all 0.3s ease;
}

.fcb-premium-icon:hover {
  transform: translateY(-5px) rotate(10deg);
  box-shadow: 0 10px 20px rgba(0, 78, 152, 0.4);
}

/* 高级徽章效果 */
.fcb-premium-badge {
  display: inline-block;
  padding: 5px 12px;
  background: var(--fcb-gradient-blue-red);
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.fcb-premium-badge:hover {
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

/* 高级输入框效果 */
.fcb-premium-input {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid var(--fcb-gray-light);
  border-radius: var(--fcb-border-radius-medium);
  font-size: 16px;
  transition: all 0.3s ease;
  background-color: var(--fcb-white);
}

.fcb-premium-input:focus {
  outline: none;
  border-color: var(--fcb-blue-bottom);
  box-shadow: 0 0 0 3px rgba(0, 78, 152, 0.2);
  transform: translateY(-2px);
}

/* 高级下拉菜单效果 */
.fcb-premium-dropdown {
  position: relative;
  display: inline-block;
}

.fcb-premium-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background: var(--fcb-white);
  border-radius: var(--fcb-border-radius-medium);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  z-index: 100;
}

.fcb-premium-dropdown:hover .fcb-premium-dropdown-content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.fcb-premium-dropdown-item {
  padding: 10px 20px;
  color: var(--fcb-gray-dark);
  transition: all 0.2s ease;
}

.fcb-premium-dropdown-item:hover {
  background-color: rgba(0, 78, 152, 0.1);
  color: var(--fcb-blue-bottom);
  padding-left: 25px;
}

/* 高级加载动画 */
.fcb-premium-loader {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.fcb-premium-loader::before,
.fcb-premium-loader::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
}

.fcb-premium-loader::before {
  border-top-color: var(--fcb-blue-bottom);
  border-right-color: var(--fcb-red-bottom);
  border-bottom-color: var(--fcb-ball-yellow);
  animation: spin 1.5s linear infinite;
}

.fcb-premium-loader::after {
  border-top-color: var(--fcb-red-bottom);
  border-right-color: var(--fcb-ball-yellow);
  border-bottom-color: var(--fcb-blue-bottom);
  animation: spin 1s ease-in-out infinite reverse;
}

/* 高级开关效果 */
.fcb-premium-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.fcb-premium-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.fcb-premium-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--fcb-gray);
  transition: all 0.4s ease;
  border-radius: 34px;
}

.fcb-premium-switch-slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--fcb-white);
  transition: all 0.4s ease;
  border-radius: 50%;
}

.fcb-premium-switch input:checked + .fcb-premium-switch-slider {
  background: var(--fcb-gradient-blue-red);
}

.fcb-premium-switch input:checked + .fcb-premium-switch-slider:before {
  transform: translateX(26px);
}

/* 高级提示框效果 */
.fcb-premium-tooltip {
  position: relative;
  display: inline-block;
}

.fcb-premium-tooltip-text {
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background: var(--fcb-blue-dark);
  color: var(--fcb-white);
  padding: 8px 12px;
  border-radius: var(--fcb-border-radius-medium);
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.fcb-premium-tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: var(--fcb-blue-dark) transparent transparent transparent;
}

.fcb-premium-tooltip:hover .fcb-premium-tooltip-text {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-5px);
}

/* 高级进度条效果 */
.fcb-premium-progress {
  height: 10px;
  background-color: var(--fcb-gray-light);
  border-radius: var(--fcb-border-radius-small);
  overflow: hidden;
  position: relative;
}

.fcb-premium-progress-bar {
  height: 100%;
  background: var(--fcb-gradient-blue-red);
  border-radius: var(--fcb-border-radius-small);
  transition: width 0.5s ease;
  position: relative;
  overflow: hidden;
}

.fcb-premium-progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: progressShine 2s infinite;
}

@keyframes progressShine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* 高级表格效果 */
.fcb-premium-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: var(--fcb-border-radius-medium);
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.fcb-premium-table th {
  background: var(--fcb-gradient-blue-red);
  color: var(--fcb-white);
  padding: 15px;
  text-align: left;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

.fcb-premium-table td {
  padding: 15px;
  border-bottom: 1px solid var(--fcb-gray-light);
  transition: all 0.2s ease;
}

.fcb-premium-table tr:last-child td {
  border-bottom: none;
}

.fcb-premium-table tr:hover td {
  background-color: rgba(0, 78, 152, 0.05);
  transform: scale(1.01);
}

/* 高级警告框效果 */
.fcb-premium-alert {
  padding: 15px 20px;
  border-radius: var(--fcb-border-radius-medium);
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.fcb-premium-alert::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
}

.fcb-premium-alert-info {
  background-color: rgba(0, 78, 152, 0.1);
  color: var(--fcb-blue-bottom);
}

.fcb-premium-alert-info::before {
  background-color: var(--fcb-blue-bottom);
}

.fcb-premium-alert-success {
  background-color: rgba(76, 175, 80, 0.1);
  color: var(--fcb-success);
}

.fcb-premium-alert-success::before {
  background-color: var(--fcb-success);
}

.fcb-premium-alert-warning {
  background-color: rgba(238, 189, 1, 0.1);
  color: var(--fcb-ball-yellow);
}

.fcb-premium-alert-warning::before {
  background-color: var(--fcb-ball-yellow);
}

.fcb-premium-alert-error {
  background-color: rgba(164, 1, 66, 0.1);
  color: var(--fcb-red-bottom);
}

.fcb-premium-alert-error::before {
  background-color: var(--fcb-red-bottom);
}

/* 高级标签效果 */
.fcb-premium-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fcb-premium-tab-headers {
  display: flex;
  border-bottom: 2px solid var(--fcb-gray-light);
  margin-bottom: 20px;
}

.fcb-premium-tab-header {
  padding: 12px 20px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.fcb-premium-tab-header.active {
  color: var(--fcb-blue-bottom);
  font-weight: 600;
}

.fcb-premium-tab-header.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  background: var(--fcb-gradient-blue-red);
}

.fcb-premium-tab-content {
  display: none;
  padding: 20px 0;
}

.fcb-premium-tab-content.active {
  display: block;
  animation: fadeIn 0.5s ease;
}